import React, { useEffect } from 'react';
import styles from './Technos.module.scss';
import { RichText } from '../../../scripts/contentful';
import { gsap } from 'gsap/dist/gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

const Technos = ({ technosRef, content = {}, title = '' }) => {
  // Change path value
  useEffect(() => {
    ScrollTrigger.matchMedia({
      // desktop
      '(min-width: 800px)': function () {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: technosRef.current,
            scrub: true,
            start: `top ${25 + 20 + 32}`,
            end: `top ${25 + 20}`,
          },
        });

        tl.to(
          '#path',
          {
            duration: 1,
            text: '/ Nos technos',
          },
          0
        );
      },

      // small
      '(min-width: 600px) and (max-width: 799px)': function () {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: technosRef.current,
            scrub: true,
            start: `top ${20 + 20 + 32}`,
            end: `top ${20 + 20}`,
          },
        });

        tl.to(
          '#path',
          {
            duration: 1,
            text: '/ Nos technos',
          },
          0
        );
      },

      // mobile
      '(max-width: 599px)': function () {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: technosRef.current,
            scrub: true,
            start: `top ${15 + 15 + 32}`,
            end: `top ${15 + 15}`,
          },
        });

        tl.to(
          '#path',
          {
            duration: 1,
            text: '/ Nos technos',
          },
          0
        );
      },
    });
  }, []);

  return (
    <section className={styles.Technos} ref={technosRef}>
      <div className='layout row'>
        <div className={styles.Technos__table + ' ' + styles.Technos__content}>
          <h2>{title}</h2>
          <RichText content={content.json} />
        </div>
        <div className={styles.Technos__table}>
          <picture>
            <source type='image/webp' srcSet='/img/technos.webp' />

            <img
              src='/img/technos.png'
              srcSet='/img/technos.png'
              alt='Nos technos'
              loading='lazy'
              decoding='async'
            />
          </picture>
        </div>
      </div>
    </section>
  );
};

export default Technos;
