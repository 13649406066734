import React, { useEffect } from 'react';
import styles from './Missions.module.scss';
import { RichText } from '../../../scripts/contentful';
import { gsap } from 'gsap/dist/gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

const Missions = ({
  MissionsRef,
  gemmeRef,
  missionRef,
  valeurRef,
  gradientRef,
  missionTitle = '',
  missionDesc = '',
  valuesTitle = '',
  valuesDesc = '',
}) => {
  // Turn navbar in white
  useEffect(() => {
    ScrollTrigger.matchMedia({
      // desktop
      '(min-width: 800px)': function () {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: MissionsRef.current,
            scrub: true,
            start: `top ${25 + 20 + 32}`,
            end: `top ${25 + 20}`,
          },
        });

        tl.to(
          '#t-menuHamburger div span',
          { background: '#fff', duration: 1 },
          0
        )
          .to('#t-menuHamburger p', { color: '#fff', duration: 1 }, 0)
          .to('#filArianne p', { color: '#fff', duration: 1 }, 0)
          .fromTo(
            '#logo',
            {
              '-webkit-filter': 'brightness(1)',
              filter: 'brightness(1)',
            },
            {
              duration: 1,
              '-webkit-filter': 'brightness(5)',
              filter: 'brightness(5)',
            },
            0
          )
          .to(
            '#path',
            {
              duration: 1,
              text: '/ Valeurs & Missions',
            },
            0
          );
      },

      // small
      '(min-width: 600px) and (max-width: 799px)': function () {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: MissionsRef.current,
            scrub: true,
            start: `top ${20 + 20 + 32}`,
            end: `top ${20 + 20}`,
          },
        });

        tl.to(
          '#t-menuHamburger div span',
          { background: '#fff', duration: 1 },
          0
        )
          .to('#t-menuHamburger p', { color: '#fff', duration: 1 }, 0)
          .to('#filArianne p', { color: '#fff', duration: 1 }, 0)
          .fromTo(
            '#logo',
            {
              '-webkit-filter': 'brightness(1)',
              filter: 'brightness(1)',
            },
            {
              duration: 1,
              '-webkit-filter': 'brightness(5)',
              filter: 'brightness(5)',
            },
            0
          )
          .to(
            '#path',
            {
              duration: 1,
              text: '/ Valeurs & Missions',
            },
            0
          );
      },

      // mobile
      '(max-width: 599px)': function () {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: MissionsRef.current,
            scrub: true,
            start: `top ${15 + 15 + 32}`,
            end: `top ${15 + 15}`,
          },
        });

        tl.to(
          '#t-menuHamburger div span',
          { background: '#fff', duration: 1 },
          0
        )
          .to('#t-menuHamburger p', { color: '#fff', duration: 1 }, 0)
          .to('#filArianne p', { color: '#fff', duration: 1 }, 0)
          .fromTo(
            '#logo',
            {
              '-webkit-filter': 'brightness(1)',
              filter: 'brightness(1)',
            },
            {
              duration: 1,
              '-webkit-filter': 'brightness(5)',
              filter: 'brightness(5)',
            },
            0
          )
          .to(
            '#path',
            {
              duration: 1,
              text: '/ Valeurs & Missions',
            },
            0
          );
      },
    });
  }, []);

  return (
    <section className={styles.Missions} ref={MissionsRef}>
      <div className={styles.Missions__content}>
        <div className={styles.Missions__content__gradient}>
          <div
            className={styles.Missions__content__gradient__bg}
            ref={gradientRef}
          ></div>

          <div className={styles.Missions__content__gradient__container}>
            <div ref={gemmeRef} className={styles.move}>
              <div
                className={
                  styles.Missions__content__gradient__container__missionBlock
                }
                ref={missionRef}
              >
                <h2>{missionTitle}</h2>

                <RichText content={missionDesc.json} />

                <div
                  className={
                    styles.Missions__content__gradient__container__missionLine
                  }
                >
                  <img
                    src='/img/missions/line-missions.svg'
                    alt=''
                    role='presentation'
                  />
                </div>
              </div>

              <picture className='gemme'>
                <source
                  type='image/webp'
                  srcSet='/img/home/logo-abecedaire2.webp'
                />

                <img
                  src='/img/home/logo-abecedaire2.webp'
                  alt=''
                  decoding='async'
                />
              </picture>

              <div
                className={
                  styles.Missions__content__gradient__container__valeurBlock
                }
                ref={valeurRef}
              >
                <h2>{valuesTitle}</h2>

                <RichText content={valuesDesc.json} />
                <div
                  className={
                    styles.Missions__content__gradient__container__valeursLine
                  }
                >
                  <img
                    src='/img/missions/line-valeurs.svg'
                    alt=''
                    role='presentation'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Missions;
