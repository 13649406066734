import React from 'react';
import HeroHeading from '../../../components/HeroHeading/HeroHeading';
import styles from './HeroSection.module.scss';

const HeroSection = () => {
  return (
    <section className={styles.HeroSection}>
      <div className={styles.HeroSection__canvas}>
        <HeroHeading title='ABECEDAIRE' />

        <picture className='gemme'>
          <source type='image/webp' srcSet='/img/home/logo-abecedaire1.webp' />

          <img src='/img/home/logo-abecedaire1.png' alt='' decoding='async' />
        </picture>

        <div className={styles.HeroSection__canvas__scrollIcon}>
          <img
            src='/img/svg/icn-scroll.svg'
            alt=''
            role='presentation'
            loading='lazy'
            width='15'
            height='15'
          />
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
