import React from 'react';
import styles from './HeroHeading.module.scss';

const HeroHeading = ({ title }) => {
  return (
    <>
      <h1 className={styles.HeroHeading}>{title}</h1>
      <span className={styles.Overlay}>{title}</span>
      {/*<span className={styles.Overlay}>{title}</span>*/}
    </>
  );
};

export default HeroHeading;
