import React, { useState, useEffect } from 'react';
import styles from './Services.module.scss';
import HorizontalElement from '../../../components/HorizontalElement/HorizontalElement';
import Button from '../../../components/Button/Button';
import { RichText } from '../../../scripts/contentful';
import { vh } from '../../../utils/getViewportSize';
import { gsap } from 'gsap/dist/gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

const Services = ({ servicesRef, title, description, services = [] }) => {
  const [active, setActive] = useState(null);

  // Change path value
  useEffect(() => {
    ScrollTrigger.matchMedia({
      // desktop
      '(min-width: 800px)': function () {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: servicesRef.current,
            scrub: true,
            start: `top ${25 + 20 + 32}`,
            end: `top ${25 + 20}`,
          },
        });

        tl.to(
          '#path',
          {
            duration: 1,
            text: '/ Nos services',
          },
          0
        );
      },

      // small
      '(min-width: 600px) and (max-width: 799px)': function () {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: servicesRef.current,
            scrub: true,
            start: `top ${20 + 20 + 32}`,
            end: `top ${20 + 20}`,
          },
        });

        tl.to(
          '#path',
          {
            duration: 1,
            text: '/ Nos services',
          },
          0
        );
      },

      // mobile
      '(max-width: 599px)': function () {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: servicesRef.current,
            scrub: true,
            start: `top ${15 + 15 + 32}`,
            end: `top ${15 + 15}`,
          },
        });

        tl.to(
          '#path',
          {
            duration: 1,
            text: '/ Nos services',
          },
          0
        );
      },
    });
  }, []);

  return (
    <section className={styles.Services} ref={servicesRef}>
      <div className='layout wide'>
        <div className={styles.Services__title}>
          <h2>{title}</h2>
          <Button
            id={'goto-services-desktop'}
            gradient={true}
            link={'/services'}
          >
            Accéder à la page services
          </Button>
        </div>

        <RichText
          content={description}
          paragraphClassName={styles.Services__text}
        />

        <Button id={'goto-services-mobile'} gradient={true} link={'/services'}>
          Accéder à la page services
        </Button>

        <HorizontalElement className={styles.Services__list}>
          <div className={styles.Services__list__padding}></div>
          <div className={styles.Services__list__ctx}>
            {services.map((service, i) => (
              <a
                href={`/services/${service.slug}`}
                key={i}
                className={`${styles.Services__list__single} ${
                  active === service.slug
                    ? styles.Services__list__singleActive
                    : ''
                }`}
                onMouseOver={() => setActive(service.slug)}
                onMouseOut={() => setActive(null)}
              >
                <div className={styles.Services__list__single__content}>
                  <img
                    width='70'
                    height='70'
                    src={service?.iconBlue?.url}
                    alt={service?.iconBlue?.title}
                  />
                  <p>{service.title}</p>
                  <RichText content={service?.homeAbstract?.json} />
                </div>
                {active === service.slug ? (
                  <div
                    className={styles.Services__list__singleActive__moreActive}
                  >
                    <img
                      width='40'
                      height='40'
                      src='/img/svg/icn-arrow-right.svg'
                      alt='En savoir plus'
                    />
                  </div>
                ) : (
                  <div className={styles.Services__list__single__more}>
                    <img
                      width='13'
                      height='13'
                      src='/img/svg/icn-mouse.svg'
                      alt='En savoir plus'
                    />
                  </div>
                )}
              </a>
            ))}
          </div>
          <div className={styles.Services__list__padding}></div>
        </HorizontalElement>
      </div>
    </section>
  );
};

export default Services;
