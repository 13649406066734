import React, { useEffect, useRef } from 'react';
import styles from './HorizontalElement.module.scss';

const HorizontalElement = ({ children, className = '' }) => {
  let sliderRef = useRef(null);

  useEffect(() => {
    const slider = sliderRef;
    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener('mousedown', (e) => {
      isDown = true;
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener('mouseleave', () => {
      isDown = false;
    });
    slider.addEventListener('mouseup', () => {
      isDown = false;
    });
    slider.addEventListener('mousemove', (e) => {
      if (!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
    });
  }, []);

  return (
    <div
      className={`${styles.horizontal} ${className}`}
      ref={(el) => (sliderRef = el)}
    >
      {children}
    </div>
  );
};

export default HorizontalElement;
