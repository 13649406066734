import React, { useEffect, useRef } from 'react';
import HeroSection from '../containers/Home/HeroSection/HeroSection';
import Missions from '../containers/Home/Missions/Missions';
import Experience from '../containers/Home/Experience/Experience';
import Projets from '../containers/Home/Projets/Projets';
import Services from '../containers/Home/Services/Services';
import Technos from '../containers/Home/Technos/Technos';
import Device from '../utils/mobile';
import { getHomepage } from '../utils/api';
import Layout from '../components/Layout';
import { gsap } from 'gsap/dist/gsap';
import { TextPlugin } from 'gsap/dist/TextPlugin';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(TextPlugin);

const Home = ({ homepage }) => {
  const MissionsRef = useRef(null);
  const gemmeRef = useRef(null);
  const missionRef = useRef(null);
  const valeurRef = useRef(null);
  const gradientRef = useRef(null);
  const expRef = useRef(null);
  const textRef = useRef(null);
  const memorableRef = useRef(null);
  const projetsRef = useRef(null);
  const servicesRef = useRef(null);
  const etourismeRef = useRef(null);
  const technosRef = useRef(null);

  //Main timeline
  useEffect(() => {
    //Init Timeline
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: MissionsRef.current,
        scrub: true,
        start: 'top',
        end: 'bottom bottom',
      },
    });

    /* Missions Section */

    //TODO: Check animation duration
    tl.from(
      expRef.current,
      {
        display: 'none',
        duration: 0.1,
      },
      0
    )
      // Move gemme box out of the viewport
      .to(gemmeRef.current, { duration: 0.5, yPercent: -100 }, 0.1)
      // Move the gradient out of the viewport
      .to(gradientRef.current, { duration: 0.5, yPercent: -100 }, 0.1)
      // Fade out to "Missions" content
      .to(
        missionRef.current,
        {
          duration: 0.2,
          opacity: 0,
        },
        '<+=100%'
      )
      // Fade out to "Valeurs" content
      .to(
        valeurRef.current,
        {
          duration: 0.2,
          opacity: 0,
          delay: 0.1,
        },
        '<+=100%'
      )
      //
      .fromTo(
        textRef.current,
        { display: 'none' },
        {
          display: 'flex',
          duration: 0.1,
          delay: 0.1,
        },
        '<+=100%'
      )
      //
      .to(
        '#path',
        {
          duration: 0.1,
          text: '/ Expérience Utilisateur',
        },
        '<+=100%'
      )
      //
      .to(
        expRef.current,
        {
          opacity: 1,
          duration: 0.3,
        },
        '<+=100%'
      )
      //
      .to(memorableRef.current, {
        opacity: 1,
        duration: 0.3,
      })
      //
      .to(memorableRef.current, {
        letterSpacing: Device.check() === true ? '0.3rem' : '0.5rem',
        duration: 0.5,
      })
      // add delay at the end of the timeline
      .set({}, {}, 1.5);

    return () => {
      tl && tl.scrollTrigger.kill();
      tl && tl.kill();
    };
  }, []);

  //Mobile btn icon timeline
  useEffect(() => {
    let tserv;

    if (Device.check() === true) {
      const demiScreen = window.innerHeight / 2 + 'px';
      tserv = gsap.timeline({
        scrollTrigger: {
          trigger: servicesRef.current,
          scrub: true,
          start: 'bottom-=' + demiScreen,
          end: 'bottom',
          onEnter: () => {
            if (etourismeRef.current) {
              etourismeRef.current.classList.add('anim');
            }
          },
        },
      });
    }

    return () => {
      tserv && tserv.scrollTrigger.kill();
      tserv && tserv.kill();
    };
  }, []);

  return (
    <Layout pathRoot='Accueil'>
      {/* <Opener /> */}
      <HeroSection />
      <Missions
        MissionsRef={MissionsRef}
        gemmeRef={gemmeRef}
        missionRef={missionRef}
        valeurRef={valeurRef}
        gradientRef={gradientRef}
        missionTitle={homepage?.missionTitle}
        missionDesc={homepage?.missionDescription}
        valuesTitle={homepage?.valuesTitle}
        valuesDesc={homepage?.valuesDescription}
      />
      <Experience
        expRef={expRef}
        textRef={textRef}
        memorableRef={memorableRef}
        experienceTitle={homepage?.experienceTitle}
        experienceDesc={homepage?.experienceDescription}
      />

      <Projets
        projetsRef={projetsRef}
        title={homepage?.projetsTitle}
        description={homepage?.projetsDescription?.json}
        projects={homepage.nosProjetsCollection?.items}
        contactText={homepage.contactProjectAbstract?.json}
      />

      <Services
        services={homepage?.nosServicesCollection?.items}
        servicesRef={servicesRef}
        title={homepage?.servicesTitle}
        description={homepage?.servicesDescription?.json}
      />

      <Technos
        technosRef={technosRef}
        content={homepage?.technologiesDescription}
        title={homepage?.technologiesTitle}
      />
    </Layout>
  );
};
export default Home;

export async function getStaticProps({ params }) {
  const data = await getHomepage();

  return {
    props: {
      homepage: data ?? null,
    },
  };
}
