import React from 'react';
import styles from './ProjectTile.module.scss';
import Image from '../Images/Images';
import Link from 'next/link';

const ProjectTile = ({
  color = 'white',
  shadowColor = 'white',
  name,
  description,
  tags,
  tagColor,
  image,
  slug,
}) => {
  return (
    <Link href={`/realisations/${slug}`}>
      <a style={{ textDecoration: 'none' }}>
        <div
          className={styles.ProjectTile}
          style={{
            background: color,
            boxShadow: `0 8px 20px ${shadowColor}`,
          }}
        >
          <h3>{name}</h3>
          <p>{description}</p>
          <div className={styles.ProjectTile__tags}>
            {tags.map((tag, index) => (
              <div
                className={styles.ProjectTile__tags__tag}
                style={{ background: tagColor }}
                key={name + '-' + tag.title + index}
              >
                {tag.title}
              </div>
            ))}
          </div>
          <div className={styles.ProjectTile__imageWrapper}>
            <div>
              <Image url={image.url} />
            </div>
          </div>
        </div>
      </a>
    </Link>
  );
};

export default ProjectTile;
