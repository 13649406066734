import React from 'react';
import styles from './Experience.module.scss';
import Hamburger from '../../../components/Hamburger/Hamburger';
import FilArianne from '../../../components/FilArianne/FilArianne';
import Button from '../../../components/Button/Button';
import { RichText } from '../../../scripts/contentful';

const Experience = ({
  expRef,
  textRef,
  memorableRef,
  experienceTitle = '',
  experienceDesc = {},
}) => {
  return (
    <>
      <section className={styles.Experience} ref={expRef}>
        <h2 className={styles.Experience__memo} ref={memorableRef}>
          MÉMORABLE
        </h2>

        <div ref={textRef} className={styles.Experience__content}>
          <div className={styles.Experience__content__grid}>
            <h2>{experienceTitle}</h2>
            <RichText content={experienceDesc.json} />
            <div className={styles.Experience__content__ctxbutton}>
              <Button link={'/contact'}>C’est parti !</Button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Experience;
