import React, { useEffect, useState } from 'react';
import styles from './Projets.module.scss';
import Button from '../../../components/Button/Button';
import { CLEAN_STR } from '../../../utils/string';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Device from '../../../utils/mobile';
import { RichText } from '../../../scripts/contentful';
import { gsap } from 'gsap/dist/gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import ProjectTile from '../../../components/ProjectTile/ProjectTile';
import containerStyle from '../../../containers/Projects/TypeProject/TypeProject.module.scss';
import HorizontalElement from '../../../components/HorizontalElement/HorizontalElement';

const Projets = ({ projetsRef, title, description, projects, contactText }) => {
  const router = useRouter();
  const [active, setActive] = useState(CLEAN_STR(projects[0].title));
  const [current, setCurrent] = useState(null);

  useEffect(() => {
    setCurrent(projects.find((project) => CLEAN_STR(project.title) === active));
  }, [active]);

  // Turn navbar in gradient
  useEffect(() => {
    ScrollTrigger.matchMedia({
      // desktop
      '(min-width: 800px)': function () {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: projetsRef.current,
            scrub: true,
            start: `top ${25 + 20 + 32}`,
            end: `top ${25 + 20}`,
          },
        });

        tl.to(
          '#t-menuHamburger div span:nth-child(1)',
          { background: 'var(--blue)', duration: 1 },
          0
        )
          .to(
            '#t-menuHamburger div span:nth-child(2)',
            { background: '#6462b8', duration: 1 },
            0
          )
          .to(
            '#t-menuHamburger div span:nth-child(3)',
            { background: 'var(--purple)', duration: 1 },
            0
          )
          .to(
            '#t-menuHamburger p',
            { color: 'var(--darkGrey)', duration: 1 },
            0
          )
          .to(
            '#logo',
            {
              '-webkit-filter': 'brightness(1)',
              filter: 'brightness(1)',
            },
            0
          )
          .to('#filArianne p', { color: 'var(--darkGrey)', duration: 1 }, 0)
          .to(
            '#path',
            {
              duration: 1,
              text: '/ Nos réalisations',
            },
            0
          );
      },

      // small
      '(min-width: 600px) and (max-width: 799px)': function () {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: projetsRef.current,
            scrub: true,
            start: `top ${20 + 20 + 32}`,
            end: `top ${20 + 20}`,
          },
        });

        tl.to(
          '#t-menuHamburger div span:nth-child(1)',
          { background: 'var(--blue)', duration: 1 },
          0
        )
          .to(
            '#t-menuHamburger div span:nth-child(2)',
            { background: '#6462b8', duration: 1 },
            0
          )
          .to(
            '#t-menuHamburger div span:nth-child(3)',
            { background: 'var(--purple)', duration: 1 },
            0
          )
          .to(
            '#t-menuHamburger p',
            { color: 'var(--darkGrey)', duration: 1 },
            0
          )
          .to(
            '#logo',
            {
              '-webkit-filter': 'brightness(1)',
              filter: 'brightness(1)',
            },
            0
          )
          .to('#filArianne p', { color: 'var(--darkGrey)', duration: 1 }, 0)
          .to(
            '#path',
            {
              duration: 1,
              text: '/ Nos réalisations',
            },
            0
          );
      },

      // mobile
      '(max-width: 599px)': function () {
        const tl = gsap.timeline({
          scrollTrigger: {
            trigger: projetsRef.current,
            scrub: true,
            start: `top ${15 + 15 + 32}`,
            end: `top ${15 + 15}`,
          },
        });

        tl.to(
          '#t-menuHamburger div span:nth-child(1)',
          { background: 'var(--blue)', duration: 1 },
          0
        )
          .to(
            '#t-menuHamburger div span:nth-child(2)',
            { background: '#6462b8', duration: 1 },
            0
          )
          .to(
            '#t-menuHamburger div span:nth-child(3)',
            { background: 'var(--purple)', duration: 1 },
            0
          )
          .to(
            '#t-menuHamburger p',
            { color: 'var(--darkGrey)', duration: 1 },
            0
          )
          .to(
            '#logo',
            {
              '-webkit-filter': 'brightness(1)',
              filter: 'brightness(1)',
            },
            0
          )
          .to('#filArianne p', { color: 'var(--darkGrey)', duration: 1 }, 0)
          .to(
            '#path',
            {
              duration: 1,
              text: '/ Nos réalisations',
            },
            0
          );
      },
    });
  }, []);

  // add gradient background to nav tag
  useEffect(() => {
    ScrollTrigger.create({
      trigger: projetsRef.current,
      scrub: true,
      start: 'top',
      end: 'top',
      onEnter: () => {
        gsap.set('#nav', {
          duration: 1,
          background: `linear-gradient(180deg,
          rgba(255,255,255,1) 65%,
          rgba(255,255,255,0.9) 70%,
          rgba(255,255,255,0.6) 80%,
          rgba(255,255,255,0.5) 85%,
          rgba(255,255,255,0.0) 100%)
        `,
        });
      },
      onEnterBack: () => {
        gsap.set('#nav', {
          background: 'transparent',
        });
      },
    });
  }, []);

  const handleProjectClick = (project) => {
    if (Device.check() === true) {
      router.push(`/realisations/${project.slug}`);
    } else {
      setActive(CLEAN_STR(project.title));
    }
  };

  return (
    <section className={styles.Projets} ref={projetsRef}>
      <div className={`layout wide`}>
        <div className={styles.Projets__title}>
          <h2>{title}</h2>
          <Button
            id={'goto-realisations-desktop'}
            gradient={true}
            link={'/realisations'}
          >
            Accéder à la page réalisations
          </Button>
        </div>

        {<RichText content={description} />}

        <div className={styles.Projets__button}>
          <Button
            id={'goto-realisations-mobile'}
            gradient={true}
            link={'/realisations'}
          >
            Accéder à la page réalisations
          </Button>
        </div>

        <HorizontalElement className={styles.Projets__list}>
          <div className={styles.Projets__list__padding}></div>
          <div
            // className={`${containerStyle.TypeProject__content__list} layout wide row stretch`}
            className={styles.Projets__list__ctx}
          >
            {projects.map((project) => (
              <ProjectTile
                key={project.title}
                color={project.color}
                shadowColor={project.shadowColor}
                name={project.title}
                description={project.subtitle}
                tags={project.tagsCollection.items}
                tagColor={project.tagColor}
                image={project?.tileBanner || project?.banner}
                slug={project.slug}
                onClick={() => handleProjectClick(project)}
              />
            ))}
          </div>
          <div className={styles.Projets__list__padding}></div>
        </HorizontalElement>
      </div>
    </section>
  );
};

export default Projets;
